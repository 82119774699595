import ErrorAPI from "@/errors/ErrorAPI";

class OnboardApiError extends ErrorAPI {
  constructor(
    response,
    clientMessage = "Não foi possível concluir a operação, tente novamente ou entre em contato com nossa equipe através do botão abaixo."
  ) {
    super(response?.status || 400, response?.data?.message, clientMessage, response.data);
  }
}

export default OnboardApiError;
