/**
 * ApiError representa um erro ao realizar uma requisição em uma api externa.
 */
// todo refatorar jeito que o erro é retornado
class ErrorAPI extends Error {
  constructor(statusCode, message, clientMessage, data) {
    super(`(${statusCode}): ${message}`);
    this.clientMessage = clientMessage;
    this.statusCode = statusCode;
    this.data = data;
    this.errorMessage = message;
  }
}

export default ErrorAPI;
